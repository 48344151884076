import {defineStore} from 'pinia'

export const useBdThemesAppStore = defineStore('bdThemesApp', {
    state: () => ({
        bdThemesApp: bdThemesPublicConfig,
        settings: {},
        authorized: {},
        abort_code: 0,
        abort_message: '',
        sidebar: [],
        sidebarAll: [],
        products:[],
    }),
    getters: {
        getBdThemesApp: state => state.bdThemesApp,
        isUserLoggedIn: state => !!state.bdThemesApp?.isLoggedIn,
        getAuthor: state => state.authorized?.author,
        getSidebar: state => state.sidebar,
        getPermissions: state => state.authorized?.author?.permissions,
        getHomeRoute: state => state.bdThemesApp?.home_route,

        getSettings: state => state.bdThemesApp?.settings,
        getRowsPerPage: state => state.bdThemesApp?.settings?.rows_per_page,
        getAbortCode: state => state.abort_code,
        getAbortMessage: state => state.abort_message,
    },
    actions: {
        async setAbort(code = 0, message = ''){
            this.abort_code = code;
            if(message){
                this.abort_message = message;
            }
        },

        async delay(ms){
            return new Promise(resolve => setTimeout(resolve, ms));
        },

        async authorization() {
            return axios.get('/app/authorization')
                .then((resp) => {
                    this.authorized = resp?.data?.authorized;
                    this.sidebarAll = resp?.data?.authorized?.author?.sidebar;
                    this.bdThemesApp = resp?.data?.bdThemesApp;
                    this.products = resp?.data?.authorized.products;
                })
                .catch((error) => {
                    if (error?.response?.status === 401) {
                        commit('RESET_AUTHORIZATION');
                    }
                    // httpResponse.axiosThrowPage(error);
                })
        },
    }
});
